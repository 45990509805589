
export const environment = {
   name : "PROD",
   production: true,
   languageCdnUrl: "https://lms-cdn.mee.macmillaneducation.com/useruploadedfiles/languagefiles/meeplatform",
   menuToggleStateUrl: "https://lms-cdn.mee.macmillaneducation.com/useruploadedfiles/menuconfig/meeplatform",
   ssrNodeApiUrl: "https://mee.macmillaneducation.com/api/getLanguageData",
   authUrl: "https://api.mee.macmillaneducation.com/ACEAuth/macx/oauth/token",
   aceApiBaseUrl: "https://api.mee.macmillaneducation.com/ACEApi/Api/v1/",
   lmsCDNPath: "https://lms-cdn.mee.macmillaneducation.com/cdn",
   redisUrl : "redis://lms-fecache.mee.macmillaneducation.com:6379",
   authCoreUrl: "https://api.mee.macmillaneducation.com/acecoreauth/meeplatform/oauth/token",
   aceApiBaseCoreUrl: "https://api.mee.macmillaneducation.com/acecoreapi/api/v1/",
   macmillanEducationUrl: "https://www.macmillaneducationeverywhere.com/",
   siteUrl:"https://mee.macmillaneducation.com",
   applicationBuildVersion:"2.7.0.50",
   redirectSSOBaseUrl:"https://www.macmillaneducationeverywhere.com/redirectsso",
   appDownloadAndroidUrl:"https://play.google.com/store/apps/details?id=com.macmillaneducationeverywhere.mee",
   appDownloadIosUrl:"https://apps.apple.com/us/app/macmillan-education-everywhere/id1645138716",
   assetsCdnUrl:"https://lms-cdn.mee.macmillaneducation.com/useruploadedfiles/staticfiles/meeplatform/",
   printPdfBaseUrl:"https://printapi.mee.macmillaneducation.com",
   logoutUrl:"https://identity.macmillaneducationeverywhere.com/connect/endsession",
   retryAttempts:"2",
   rewardsURL:"https://rewards.macmillaneducationeverywhere.com",
   eplannerURL:"https://eplanner.macmillaneducationeverywhere.com/viewyourplans",
   eplannerParentURL:"https://eplanner.macmillaneducationeverywhere.com/eplanner",
   pendoAPIKey :"11adffda-d459-40d1-5001-2d91812e9d1b",
   countryCodeUrl: "https://pro.ip-api.com/json?key=fn431iAMaaPX5zH",
   batchSize: "50",
   isOBVApp: false,
   obvAppName: "MEEPlatform"
};
